.page-admin {
  h5 {
    color: white;
  }

  .tabs-wrapper {
    background: #dddddd;
    width: fit-content;
    display: inline-block;
    border-radius: 7px;

    button {
      border: none;
      background: transparent;
      font-size: 15px;
      padding: 5px 10px;
    }

    .button-active {
      font-weight: bold;
    }
  }

  .table-admin {
    margin-top: 20px;

    table {
      th {
        font-weight: 500;
      }
    }

    .user-button {
      text-align: right;

      button {
        background: #bbb;
        border-radius: 10px;
        border: none;
        padding: 3px 7px;
        min-width: 100px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
  }

  .table-records {
    table {
      th {
        color: white;
        font-weight: 600;
      }

      tr td {
        border-bottom: none;
        padding: 4px 16px 0;

        p.sold-date {
          margin: 0;
          background: #858585;
          width: fit-content;
          padding: 0 10px;
          border-radius: 10px;
          font-weight: 600;
        }

        &:first-child {
          font-weight: 600;
          color: white;
        }
      }
    }
  }

  .button-add,
  .button-import {
    background: #bbbbbb;
    padding: 5px 10px;
    border-radius: 7px;
    border: 0;
    font-size: 15px;
    min-width: 100px;
    margin-left: 15px;
    font-weight: 600;
  }
}
.navbar {
  position: relative;
  padding: 20px;
  z-index: 99;
}
.navbar-collapse {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 1%;
  top: 1%;
  z-index: -1;
  margin-top: 50px;
  opacity: 0.9;
}
.nav-link {
  color: white !important;
  font-size: 24px;
  font-weight: bold;
}
.navbar-nav {
  background-color: #212121;
  border: gray solid 1px;
  max-width: 300px;
}
.nav-item {
  padding: 20px;
}
.bullet-icon {
  max-width: 10%;
}
