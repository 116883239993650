.page-signup-buttons {
  
  .button-main {
    max-width: 350px;
    margin-bottom: 20px;
  }
  h1 {
    color: white;
    font-weight: 400;
    margin-bottom: 2em;
  }
  h2 {
    color: #bbbbbb;
    font-weight: 400;
    margin-bottom: 2em;
  }
  a {
    text-decoration: none;
    display: block;
    margin: 0 auto 20px;
    text-align: center;
  }
}

.page-signup {
  text-align: center;

  input {
    text-align: center;
    color: #fff;
    font-size: 24px;
    border: none;
    border-bottom: 1px solid #bbb;
    background: transparent !important;
    outline: none;

    &:focus {
      outline: none;
      color: #fff;
    }
  }

  label {
    text-align: center;
    color: #fff;
    font-weight: 400;
  }

  @media (max-width: 767px) {
    input {
      font-size: 16px;
    }

    label {
      font-size: 12px;
    }
  }
}
