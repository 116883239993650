.page-forgotpassword {
  h1,
  h2 {
    color: white;
    font-weight: 400;
  }

  .email-wrapper {
    label {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 15px;
    }
  }

  .button-main {
    max-width: 300px;
    margin-bottom: 20px;
    font-size: 20px;
    margin-top: 3em;
  }

  .forgot-description {
    display: flex;
    border-bottom: 1px solid #adadad;
    padding: 20px;
    margin-bottom: 6em;

    h3 {
      font-weight: 400;
      color: white;
      margin-top: 15px;
    }

    img {
      max-width: 94px;
      max-height: 94px;
      margin-right: 20px;
    }
  }

  .confirmed-description {
    margin-top: 6em;
    text-align: center;

    img {
      max-height: 70px;
    }

    h3 {
      font-weight: 400;
      color: #bbb;
      margin-bottom: 6em;
    }
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 22px;
      margin-bottom: 3em;
    }

    .forgot-description {
      flex-direction: column;
      align-items: center;
      border-style: hidden;
      margin-bottom: 0;

      img {
        max-height: 36px;
        margin: 0;
      }
    }

    .confirmed-description {
      margin-top: 0;
    }

    .forgot-description,
    .confirmed-description {
      h3 {
        font-size: 18px;
        color: #bbbbbb;
        text-align: center;
      }
    }

    img[alt="check"] {
      max-width: 20%;
    }

    img {
      max-width: 24px;
      margin-bottom: 5%;
    }

    label {
      display: none;
    }

    a.button-main {
      margin-top: 2em;
    }
  }
}
