.page-login {
  @media (max-width: 767px) {
    background: #171717 url("../../resources/images/header-bg.png") center
      center no-repeat;
    background-size: cover;
  }

  .button-main {
    margin-top: 190px;
  }

  @media (max-width: 767px) {
    form {
      label,
      input {
        font-size: 16px;
      }
    }
  }
}
