.scanner-container {
  background: #000;
  border: 1px solid white;
  color: white;

  .scanner-wrapper {
    background: url("../../resources/images/scanner-border.jpg") center center
      no-repeat;
    background-size: cover;
    width: 300px;
    height: 300px;
    padding: 9px;
    margin: 0 auto;
  }

  .camera-select {
    font-size: 14px;
    margin-bottom: 5px;
  }

  select {
    padding: 5px 7px;
    background: black;
    color: white;
    font-family: Montserrat, sans-serif;
    border-radius: 3px;
    border: 1px solid white;
  }
}

.button-exit {
  background: black;
  padding: 5px;
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  position: absolute;
  top: -60px;
  right: 10px;
}
