.page-directory {
  .wrapper-search {
    padding-bottom: 2em;

    input {
      border-radius: 20px;
    }

    button {
      color: white;
      font-size: 18px;
      background: transparent;
      border: none;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .wrapper-table {
    height: calc(100vh - 20%);
    min-height: 500px;

    * {
      color: white;
      font-size: 14px;
      font-family: "Montserrat", sans-serif;
    }

    svg {
      color: white;
    }

    .MuiPaper-root {
      background: transparent;

      th {
        background: transparent;
      }

      .MuiInput-input {
        border-radius: 15px;
        color: #000;

        &:focus,
        &:active {
          outline: none;
          border: none;
          text-decoration: none;
        }
      }

      .MUIDataTableToolbar-actions-17 {
        * {
          font-size: 20px;
        }
      }

      .MuiTableHead-root {
        .MuiTableCell-root {
          border-bottom: 3px solid #342f20;
        }
      }

      .MuiTableFooter-root {
        .MuiTableCell-root {
          border: none;
        }

        .MuiToolbar-gutters {
          border-bottom: 3px solid #342f20;
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-root {
          border: none;
        }
      }

      .MUIDataTablePagination-navContainer-86,
      .MUIDataTablePagination-navContainer-262,
      .MUIDataTablePagination-navContainer-350 {
        margin-top: 2em;
        // justify-content: center;

        * {
          font-size: 20px;
          color: #9fa2b4;
        }
      }
    }

    .MuiDataGrid-root,
    // .MuiDataGrid-columnsContainer,
    .MuiDataGrid-cell {
      border: none;
    }

    .MuiTableFooter-root {
      tr td > div {
        justify-content: center;
      }
    }
  }

  .button-menu {
    background: none;
    border: none;
  }

  .dropdown-menu {
    background: #000;
    border: 1px solid #bbb;

    img {
      max-width: 20px;
      margin-right: 10px;
    }

    .dropdown-item {
      &:hover {
        color: #000;
      }
    }
  }

  button[aria-label="Delete Selected Rows"] {
    display: none;
  }
}

.hidden-qr {
  position: fixed;
  left: -1000px;
  top: 0;
}

.MuiPaper-root.MUIDataTableToolbar-filterPaper-24 {
  background: #000 !important;
  .MUIDataTableFilter-root-89 {
    background: #000 !important;
    border: 1px solid #bbb;

    * {
      color: white;
    }
  }

  .MUIDataTableFilter-reset-93 {
    button {
      border: 1px solid #bbb;
    }
  }
}

.MUIDataTablePagination-navContainer-174 {
  justify-content: center !important;

  * {
    @media (max-width: 435px) {
      font-size: 12px !important;
    }
  }
}

.MUIDataTableToolbar-actions-105 {
  @media (max-width: 767px) {
    span {
      &:nth-child(3) {
        display: none;
      }
    }
  }
}

.footer-buttons {
  text-align: center;
  margin-top: 2em;
  ul {
    list-style-type: none;
    li {
      display: inline-block;

      &:first-child {
        button {
          border-left: none;
        }
      }

      button {
        border: none;
        background: transparent;
        line-height: 30px;
        font-size: 18px;
        padding: 0 30px;
        border-left: 2px solid #342f20;

        svg {
          margin-left: 5px;
        }

        @media (max-width: 1000px) {
          padding: 0 15px;
          span {
            display: none;
          }
        }
      }
    }
  }
}

.bulk-add-container {
  background: #e2e2e2;
  border-radius: 4px;
  text-align: center;

  .button-exit {
    position: absolute;
    right: 25px;
    top: 25px;
    background: transparent;
    border: none;
    transition: 0.2s ease-in-out all;
    padding: 0;

    &:hover {
      color: #505050;
      background: transparent;
      transition: 0.2s ease-in-out all;
    }
  }

  .dropzone-wrapper {
    padding: 20px;
  }

  .modal-button {
    background: #000;
    color: white;
    border: 0;
    border-radius: 4px;
    padding: 5px 15px;
    transition: 0.2s ease-in-out all;
    min-width: 120px;

    &:hover {
      background: #313131;
      transition: 0.2s ease-in-out all;
    }
  }
}
