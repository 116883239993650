.page-home {

  @media (max-width: 767px) {
    background: #171717 url("../../resources/images/header-bg.png") center
      center no-repeat;
    background-size: cover;
  }

  a {
    text-decoration: none;
    display: block;
    margin: 0 auto 20px;
  }

  .button-main {
    max-width: 300px;
    margin-bottom: 20px;
  }

  .button-sublink {
    font-size: 20px;
    color: white;
    border: 0;
    border-bottom: 1px solid white;
    background: transparent;
    padding: 0 0 5px;
    margin-bottom: 10px;
  }

  .small-links {
    a {
      display: inline-block;

      &:first-child {
        margin-right: 40px;
      }
    }
  }
}
