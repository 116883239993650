.header-main {
  background: url("../../resources/images/header-bg.png") center center
    no-repeat;
  background-size: cover;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  #logo {
    @media (max-width: 767px) {
      max-width: 120px;
    }
  }

  a {
    text-decoration: none;
    width: fit-content;

    h1 {
      font-size: 30px;
      font-family: "Roboto Mono", sans-serif;
      color: #bbb;
      margin: 0;
      width: fit-content;
      display: inline-block;

      @media (max-width: 767px) {
        // text-align: center;
        font-size: 18px;
      }
    }
  }

  .button-menu {
    background: transparent;
    border: none;
    color: white;
    float: right;
  }

  .logo-centered {
    @media (max-width: 767px) {
      text-align: center;
      justify-content: center;
    }
  }

  .user-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: right;

    > div {
      display: flex;
      justify-content: flex-end;

      div {
        padding: 15px;

        @media (max-width: 767px) {
          padding: 0 10px 0 0;
          text-align: right;
        }

        a {
          color: white;
          font-weight: 500;
        }

        svg {
          height: 30px;
          width: 30px;

          @media (max-width: 767px) {
            height: 16px;
            width: 16px;
          }
        }

        &:first-child {
          border-right: 2px solid white;
        }
      }

      button {
        border: none;
        background: transparent;
        outline: none;
        color: white;

        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}

.show-bg {
  @media (max-width: 767px) {
    background: transparent;
  }
}

.sidebar-menu {
  position: fixed;
  right: -310px;
  bottom: 0;
  top: 0;
  z-index: 999;
  background: #212121;
  padding: 20px;
  transition: 0.2s ease-in-out all;
  width: 310px;

  .button-menu {
    background: transparent;
    border: none;
    color: white;
  }

  ul {
    padding: 0;
    margin-top: 30px;
    li {
      list-style: none;
      margin-bottom: 15px;
      color: white;

      img {
        max-height: 28px;
        transform: rotate(-30deg);
        margin-top: -10px;
        margin-right: 10px;
      }

      a,
      button {
        color: white;
        font-size: 26px;
        font-weight: 700 !important;
        text-decoration: none;
        background: transparent;
        border: none;
      }
    }
  }
}

.sidebar-show {
  transition: 0.2s ease-in-out all;
  right: 0;
}
