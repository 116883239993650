.page-success {
  h1 {
    margin-bottom: 3em;
    font-size: 36px;
    font-weight: 400;
  }

  p {
    font-size: 36px;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 22px;
    }

    p {
      font-size: 18px;
    }
  }
}
