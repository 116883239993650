$main-color: #0c2340;
$transition: 0.2s ease-in-out all;

body {
  background: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
}

//Defaults for the texts
label {
  color: white;
}

label,
input,
button,
a,
p {
  // font-family: "Mulish", sans-serif;
  font-family: "Montserrat", sans-serif;
}

label {
  font-weight: 700;
  margin-bottom: 5px;
}

//Input defaults
input[type="text"],
input[type="password"],
input[type="number"] {
  border-radius: 1px;
  background: #c4c4c4;
  padding: 8px 15px;
}

// a {
//   color: #4d89b7 !important;
// }

img {
  max-width: 100%;
}

.button-main {
  border: 0;
  background-color: #bbb;
  border-radius: 10px;
  padding: 10px 60px;
  font-size: 30px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  transition: $transition;
  width: 100%;
  max-width: 320px;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #717171;
    transition: $transition;
  }

  &:disabled {
    opacity: 0.6;
  }

  @media (max-width: 767px) {
    font-size: 20px;
    padding: 8px 30px;
  }

  @media (max-width: 575px) {
    font-size: 18px;
  }
}

.button-submain {
  border: 0;
  background-color: #bbb;
  border-radius: 10px;
  padding: 7px 15px;
  font-size: 22px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  transition: $transition;
  text-decoration: none;
  color: #000;

  &:hover {
    color: #717171;
    transition: $transition;
  }

  @media (max-width: 767px) {
    font-size: 18px;
    padding: 8px 30px;
  }

  @media (max-width: 575px) {
    font-size: 16px;
  }
}

.button-transparent {
  border: 0;
  background: 0;
  color: $main-color;
  padding: 0;
  text-decoration: underline;
  font-weight: 600;

  &:hover {
    color: $main-color;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.button-transparent-2 {
  border: 0;
  background: 0;
  color: $main-color;
  padding: 0;
  font-weight: 600;

  &:hover {
    color: $main-color;
    text-decoration: underline;
  }
}

.set-transparent {
  opacity: 0.25;
  transition: $transition;

  &:hover {
    opacity: 1;
    transition: $transition;
  }
}

.set-transition {
  transition: $transition;

  &:hover {
    transition: $transition;
  }
}

.text-blue {
  color: $main-color !important;
}

.text-red {
  color: red !important;
}

.text-light-blue {
  color: #4d89b7 !important;
}

.text-green {
  color: #00b16a !important;
}

.text-underline {
  text-decoration: underline;
}

//Letter Spacings
.ls-1 {
  letter-spacing: 0.05em;
}

.ls-2 {
  letter-spacing: 0.1em;
}

.ls-3 {
  letter-spacing: 0.15em;
}

.ls-4 {
  letter-spacing: 0.2em;
}

.ls-5 {
  letter-spacing: 0.25em;
}

.no-size-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

//Hidden
.xs-hidden {
  @media (max-width: 374px) {
    display: none !important;
  }
}

.sm-hidden {
  @media (max-width: 568px) {
    display: none !important;
  }
}

.mobile-hidden {
  @media (min-width: 567px) and (max-width: 767px) {
    display: none !important;
  }
}

.tablet-hidden {
  @media (min-width: 768px) and (max-width: 990px) {
    display: none !important;
  }
}

.tab-hidden {
  @media (min-width: 991px) and (max-width: 1199px) {
    display: none !important;
  }
}

.tab-below-hidden {
  @media (max-width: 1199px) {
    display: none !important;
  }
}

.desktop-hidden {
  @media (min-width: 1200px) {
    display: none !important;
  }
}

.display-none {
  display: none !important;
}

//Opacity
.opacity-10 {
  opacity: 0.1;
}

.opacity-50 {
  opacity: 0.5;
}

//Custom font-weight
.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

//Height
.h-100vh {
  height: 100vh;
}

//Overrides
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4d89b7;
  background-color: #4d89b7;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(0, 91, 160, 0.5);
}

.invalid-feedback {
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.form-control-position {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.2rem;
  text-align: center;
}
.has-icon-left {
  .form-control-position {
    right: auto;
    left: inherit;
  }
  .form-control {
    padding-right: 0.85rem;
    padding-left: 2.95rem;
  }
}
.has-icon-right {
  .form-control {
    padding-right: 2.5rem;
  }
}

.table-container {
  background: #101012;
  padding: 26px;

  > .card-body {
    padding: 0;
  }

  .pagination-options {
    margin-top: 20px;
    color: #9fa2b4;

    select {
      color: #9fa2b4;
      background: transparent;
      border: 0;
    }
  }

  .table-wrapper {
    border: 1px solid white;
    background: #1e1e22;
    min-height: calc(100vh - 250px);

    > .card-body {
      padding-bottom: 38px !important;
    }

    .button-sort {
      color: #4b506d;
      background: transparent;
      border: none;

      &:hover {
        color: white;
        background: transparent;
      }
    }

    th {
      color: #9fa2b4;
      font-weight: 400;
      font-size: 14px;
    }

    td {
      color: #dfe0eb;
      padding: 20px 16px;
    }

    th,
    td {
      font-size: 14px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  input[type="text"] {
    color: white;
    background: transparent;
    border: 1px solid white;
    border-radius: 30px;
  }

  svg {
    filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(331deg)
      brightness(104%) contrast(101%);
  }
}

//Page component default styles
.page {
  height: 100vh;
  padding-top: 7em;

  @media (max-width: 767px) {
    padding-top: 4em;
  }
}

.form-control.is-invalid {
  padding-right: 15px;
}
