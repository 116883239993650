.page-details {
  hr {
    border-top: 3px solid #726747;
  }

  h3,
  p {
    color: white;

    @media (max-width: 575px) {
      text-align: center;
    }
  }

  h3 {
    @media (max-width: 1400px) {
      font-size: 18px;
    }
  }

  p {
    padding-left: 15px;

    @media (max-width: 575px) {
      padding-left: 0;
    }
  }

  .border-wrapper {
    height: fit-content;
  }

  .details-container {
    padding: 50px 0 0;

    @media (max-width: 767px) {
      padding: 20px 0;
    }
  }

  #qr-wrapper {
    background: white;
    width: fit-content;
    margin: 0 auto;
    padding: 15px;
    margin-top: 30px;

    @media (max-width: 767px) {
      margin-top: 25px;
    }
  }

  .info-wrapper {
    padding: 80px 12px 0;

    @media (max-width: 767px) {
      padding: 30px 12px;
    }

    @media (max-width: 575px) {
      padding: 0 12px;
    }
  }

  .footer-button {
    border: none;
    background: transparent;
    line-height: 30px;
    font-size: 18px;
    padding: 0 30px;
    color: white;

    svg {
      margin-left: 5px;
    }

    @media (max-width: 1000px) {
      padding: 0 15px;
    }
  }
}

.prep-for-print {
  .info-wrapper,
  .qr-container {
    width: fit-content;
    button {
      display: none;
    }
  }
}
