.page-account {
  h2 {
    color: white;
  }

  hr {
    border-top: 1px solid #bbb;
  }

  input {
    text-align: center;
    color: #fff;
    font-size: 24px;
    border: none;
    border-bottom: 1px solid #bbb;
    background: transparent !important;
    outline: none;

    &:focus {
      outline: none;
      color: #fff;
    }
  }

  label {
    text-align: center;
    color: #fff;
    font-weight: 400;
  }

  @media (max-width: 767px) {
    input {
      font-size: 16px;
    }

    label {
      font-size: 12px;
    }
  }

  .user-info {
    margin-bottom: 30px;
    p {
      color: #bbb;
      font-size: 24px;
    }

    button {
      float: right;
      border: none;
      padding: 0;
      background: transparent;
      margin-top: -80px;
    }
  }

  .wrapper-change-password {
    label {
      color: #bbb;
      font-size: 24px;
      font-weight: 400;
    }

    input {
      text-align: center;
    }
  }

  .button-save {
    margin-top: 70px;
  }

  @media (max-width: 767px) {
    h2 {
      font-size: 20px;
    }

    .user-info {
      p {
        font-size: 16px;
      }

      button {
        margin-top: -60px;

        svg {
          height: 1em;
          width: 1em;
        }
      }
    }

    .wrapper-change-password {
      label {
        font-size: 16px;
      }

      input {
        font-size: 16px;
      }
    }
  }
}
